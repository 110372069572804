class GatedContent
{
	el: HTMLElement;
	cookieName?: string;
	constructor(el: HTMLElement)
	{
		this.el = el as HTMLElement;
		this.cookieName = el.dataset.cookieName;

		// expected pardot form will send a message to the parent window at Thank You Code
		window.addEventListener('message', e => {
			if (e.data && e.data.type === "setGatedContenCookie") {
				document.cookie = this.cookieName + "=true; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/";
				location.reload();
			}
		},false);
	}
}

document.querySelectorAll(".gated-content").forEach(el =>
{
	new GatedContent(el as HTMLElement);
});