import "seamless";

// Define the type for the seamless function
declare function seamless(iframe: HTMLIFrameElement): void;

window.onload = () => {
	// Select all iframes with the data attribute
	const iframes: NodeListOf<HTMLIFrameElement> = document.querySelectorAll('iframe[data-block-id]');
	
	// Loop through each iframe and apply the seamless function
	iframes.forEach(iframe => {
		seamless(iframe);
	});
};